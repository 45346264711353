import { useAuthStore } from './auth'
import { useUserStore } from './user'
import { setPreorderCartToken } from '~/utils/helpers/auth'
import Collection from '~/entities/Collection'

export const usePreorderStore = defineStore({
  id: 'preorder-store',
  state: () => {
    return {
      cart: null,
      isOpenModal: false,
      preorderNumber: 0,
      preorders: [],
      preordersCount: 0
    }
  },
  actions: {
    async addVariantToCart(productVariant, quantity = 1) {
      // if (!this.cart) {
      await this.createCart()
      // }
      return useApi().preorders.addItem(
        this.cartToken,
        {
          productVariant
        },
        quantity,
        {
          onSuccess: cart => {
            this.cart = cart
            try {
              const { $gtagEvent } = useNuxtApp()
              const item = cart.items[0]
              $gtagEvent.preOrder(item)
            } catch (e) {
              console.error(e)
            }
            setTimeout(() => {
              navigateTo(useLocalePath()('/checkout-preorder/'))
            }, 500)
          }
        }
      )
    },
    createCartIfNotExist() {
      if (!this.cart) {
        return this.createCart()
      }
    },
    createCart() {
      return useApi().preorders.create({
        onSuccess: cart => {
          return this.setCart(cart)
        }
      })
    },
    async setCart(cart) {
      if (cart?.tokenValue) {
        await this.fetchCart(cart)
        await useCartsMerge()
        !useAuthStore().loggedIn && setPreorderCartToken(cart?.tokenValue)
      }
    },
    fetchCart({ tokenValue }) {
      return useApi().orders.getOne(tokenValue, {
        onSuccess: res => {
          if (res?.paymentState === 'cart') {
            this.cart = res
          }
        },
        onError: () => {
          if (!useAuthStore().loggedIn) {
            return false
          }
        }
      })
    },

    changeQuantity(itemId, quantity, options) {
      if (quantity < 1) {
        return true
      }
      return useApi().orders.changeQuantity(this.cartToken, itemId, quantity, {
        onRequest: () => options.onRequest && options.onRequest(),
        onSuccess: cart => {
          this.cart = cart
        },
        onFinally: () => options.onFinally && options.onFinally()
      })
    },

    updateCart(payload = {}) {
      const userStore = useUserStore()
      return useApi().orders.update(this.cartToken, {
        email: userStore.email,
        shippingAddress: {
          firstName: userStore?.user?.firstName,
          lastName: userStore?.user?.lastName,
          phoneNumber: userStore?.user?.phoneNumber,
          countryCode: 'ua'
        },
        ...payload
      })
    },

    setModal(open = false) {
      this.isOpenModal = open
    },

    setPreorderNumber(num = 0) {
      this.preorderNumber = num
    },

    fetchPreorders(page = 1, itemsPerPage = 5, showMore = false) {
      return useApi().preorders.getAll(
        {
          page,
          itemsPerPage,
          filter: 'product_order_thumbnail'
        },
        {
          onSuccess: res => {
            if (showMore) {
              this.preorders = [...this.preorders, ...new Collection(res)?.data]
            } else {
              this.preorders = new Collection(res)?.data
            }
            this.preordersCount = new Collection(res)?.totalItems
          }
        }
      )
    }
  },
  getters: {
    cartData: state => state.cart,
    cartToken: state => state.cart?.tokenValue,
    shipmentId: state => state?.cart?.shipments?.[0]?.id,
    list: state => state.cart?.items || [],
    productList: state => state.cart?.items || [],
    count: state => state.cart?.items?.length,
    totalCost: state =>
      state.cart?.itemsTotal ? state.cart?.itemsTotal / 100 : 0,
    variantInCart: state => variantId =>
      state.cart?.items.find(({ variant }) => variant.id === variantId),
    modalVisible: state => state.isOpenModal,
    preorderOrderNumber: state => state.preorderNumber,
    preorderList: state => state.preorders,
    preorderListCount: state => state.preordersCount
  }
})
